import { FC } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  UserOutlined,
  LogoutOutlined,
  LayoutOutlined,
  SettingFilled,
  CheckCircleFilled,
  GlobalOutlined,
} from "@ant-design/icons";
import { AuthType } from "hooks/useUserAuth";
import { iSitePage } from "views/WebDesigner/data/types";

type Props = {
  navItems: iSitePage[];
};

export const PrimaryNavigation: FC<Props> = ({ navItems }) => {
  return (
    <Menu
      defaultSelectedKeys={["gallerSub"]}
      mode="inline"
      style={{ background: "none" }}
    >
      {navItems.map((i) => (
        <Menu.Item
          key="6"
          style={{ borderRadius: 0 }}
          icon={<LayoutOutlined />}
        >
          <Link to={`page/${i.name}` || ""}>{i.name}</Link>
        </Menu.Item>
      ))}
      <Menu.Divider></Menu.Divider>
      <Menu.Item
        key="44"
        icon={<CheckCircleFilled />}
        style={{ borderRadius: 0 }}
      >
        <Link to="/subscription">Subscription</Link>
      </Menu.Item>
    </Menu>
  );
};
