import { FC, ReactNode } from "react";
import { Alert, Layout } from "antd";
import { AuthType } from "hooks/useUserAuth";
import { AccountNotVerifiedBanner } from "components/accounts/AccountNotVerifiedBanner";
// import { DesktopNavLayout } from "./Desktop";
import { useIsMobile } from "hooks/useIsMobile";
import { MobileNavLayout } from "./NavMobile";
// import { SiteDocument } from "views/WebDesigner/data/inventoryService";
import { iSitePage } from "views/WebDesigner/data/types";

const { Content } = Layout;

interface Props {
  children: ReactNode;
  //   auth: AuthType;
  navItems: iSitePage[];
}

export const SiteLayout: FC<Props> = ({ children, navItems }) => {
  const isMobile = useIsMobile();

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout hasSider={!isMobile}>
          <MobileNavLayout navItems={navItems} />
          {/* {isMobile ? (
            <MobileNavLayout auth={auth} />
          ) : (
            <DesktopNavLayout auth={auth} />
          )} */}

          <Layout
            className="site-layout"
            style={{ position: "relative", background: "#fff" }}
          >
            <Content
              style={{
                overflow: "auto",
                position: "absolute",
                top: 0,
                left: 0,
                padding: "15px",
                width: "100%",
              }}
            >
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
              >
                {children}
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};
