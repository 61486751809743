import { useState, FC } from "react";
import { Layout } from "antd";
import { AuthType } from "hooks/useUserAuth";
import { PrimaryNavigation } from "../PrimaryNavigation";

const { Sider } = Layout;

interface Props {
  auth: AuthType;
}

export const DesktopNavLayout: FC<Props> = ({ auth }) => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      breakpoint="md"
      theme="light"
      style={{
        backgroundColor: "#efefef",
        borderRight: "1px solid #ddd",
      }}
    >
      <div
        style={{
          color: "rgb(77 77 77)",
          fontSize: "1.7em",
          margin: "25px",
        }}
      >
        <div className="app-logo" style={{ textAlign: "right" }}>
          GALLERY
          <br />
          STORM
        </div>
      </div>
      <PrimaryNavigation auth={auth} />
    </Sider>
  );
};
