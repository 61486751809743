import { FC } from "react";
import { Button, Skeleton } from "antd";
import { UseFetchGallery } from "publicContent/gallery/data/useGallery";
import { useParams } from "react-router-dom";
import { ImageLayout } from "./ImageLayout";
import { GetReturnType } from "./data/service";
import { PageNotFound } from "routes";
// import { PublicLayout } from "publicContent/layout.tsx";
import "./styles.css";
const internalURL = window.location.host.split(".")[0];

type PublicGalleryType = {
  data: GetReturnType;
};

export const PublicGalleryContent: FC<PublicGalleryType> = ({ data }) => {
  const { gallery, companyName } = data;
  const images = gallery?.images || [];
  return (
    <div className="site-container">
      <h1 className="title">{companyName}</h1>
      <div style={{ textAlign: "center", fontSize: "1.2em" }}>
        <a
          href="https://denniscalvert.photography"
          target="blank"
          style={{ color: "#369" }}
        >
          https://denniscalvert.photography/
        </a>
      </div>
      {!gallery && <PageNotFound />}
      {gallery.security === "password" && <h1>Log in, please</h1>}
      {gallery.security === "email" && <h1>enter email address, please</h1>}
      {gallery.security === "public" && (
        <>
          <div className="galleryActions">
            {gallery.isDownloadable && <div>Tap an image to download it</div>}
            {/* {gallery.isDownloadable && (
              <Button type="link">Download Gallery</Button>
            )}
            {gallery.isDownloadable &&
              gallery.downloadResolution === "print" && (
                <Button type="link">Download Gallery for print</Button>
              )}
            {gallery.isSocialEnabled && (
              <Button type="link">Share on Social Media</Button>
            )} */}
          </div>
          <div className="grid">
            <ImageLayout images={images} galleryId={gallery._id} />
          </div>
        </>
      )}
    </div>
  );
  // return null;
};

export const PublicGallery: FC = () => {
  const { galleryId } = useParams();
  const { data, isLoading } = UseFetchGallery(internalURL, galleryId);

  if (isLoading || !data) {
    return <Skeleton />;
  }

  return (
    // <PublicLayout companyName={data.companyName}>
    <PublicGalleryContent data={data} />
    // </PublicLayout>
  );
};
