import { useMutation, useQuery } from "@tanstack/react-query";
import { getCurrentUser } from "services/user";
import { update } from "services/user";
import { UserType } from "types/User";
export const UseFetchUser = () => {
  return useQuery({
    queryKey: ["fetchUser"],
    queryFn: getCurrentUser,
  });
};

type UserMutationProps = {
  data: Partial<UserType>;
};

export const UseUserMutation = () => {
  return useMutation({
    mutationFn: (vars: UserMutationProps) => update(vars.data),
  });
};
