import { FC, ReactNode } from "react";
import { Alert, Layout } from "antd";
import { AuthType } from "hooks/useUserAuth";
import { AccountNotVerifiedBanner } from "components/accounts/AccountNotVerifiedBanner";
import { DesktopNavLayout } from "./Desktop";
import { useIsMobile } from "hooks/useIsMobile";
import { MobileNavLayout } from "./Mobile";

const { Content } = Layout;

interface Props {
  children: ReactNode;
  auth: AuthType;
}

export const AppLayout: FC<Props> = ({ children, auth }) => {
  const isMobile = useIsMobile();

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout hasSider={!isMobile}>
          {isMobile ? (
            <MobileNavLayout auth={auth} />
          ) : (
            <DesktopNavLayout auth={auth} />
          )}

          <Layout
            className="site-layout"
            style={{ position: "relative", background: "#fff" }}
          >
            <Content
              style={{
                overflow: "auto",
                position: "relative",
              }}
            >
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
              >
                {!auth.isAccountVerified && (
                  <Alert
                    style={{ marginBottom: 25 }}
                    message="Your email address has not been verified."
                    description={<AccountNotVerifiedBanner />}
                    type="warning"
                  />
                )}
                {children}
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};
