import api from "services/base-api";
import { iSitePage } from "./types";

const baseRoute = "/sites";

interface iImageList {
  [key: string]: {
    s: string;
    l: string;
  };
}

export type SiteDocument = {
  _id: string;
  data: iSitePage[];
  images: iImageList;
};

export const get = async (id: string): Promise<SiteDocument> =>
  await api.get(`${baseRoute}/${id}`);

export const post = async (id: string, data: SiteDocument): Promise<string> =>
  await api.post(`${baseRoute}/${id}`, data);

export const destroy = async (id: string): Promise<void> =>
  api.destroy(`${baseRoute}/${id}`);
