export const TIMEZONES = [
  { value: "-11:00", label: "(GMT-11:00) Midway Island" },
  { value: "-11:00", label: "(GMT-11:00) Pago Pago" },
  { value: "-10:00", label: "(GMT-10:00) Hawaii" },
  { value: "-09:00", label: "(GMT-09:00) Alaska" },
  { value: "-08:00", label: "(GMT-08:00) Pacific Time (US & Canada)" },
  { value: "-08:00", label: "(GMT-08:00) Tijuana" },
  { value: "-07:00", label: "(GMT-07:00) Mountain Time (US & Canada)" },
  { value: "-07:00", label: "(GMT-07:00) Arizona" },
  { value: "-06:00", label: "(GMT-06:00) Central Time (US & Canada)" },
  { value: "-06:00", label: "(GMT-06:00) Mexico City" },
  { value: "-05:00", label: "(GMT-05:00) Eastern Time (US & Canada)" },
  { value: "-05:00", label: "(GMT-05:00) Bogota" },
  { value: "-04:30", label: "(GMT-04:30) Caracas" },
  { value: "-04:00", label: "(GMT-04:00) Atlantic Time (Canada)" },
  { value: "-04:00", label: "(GMT-04:00) La Paz" },
  { value: "-04:00", label: "(GMT-04:00) Santiago" },
  { value: "-03:30", label: "(GMT-03:30) Newfoundland" },
  { value: "-03:00", label: "(GMT-03:00) Brasilia" },
  { value: "-03:00", label: "(GMT-03:00) Buenos Aires" },
  { value: "-03:00", label: "(GMT-03:00) Greenland" },
  { value: "-03:00", label: "(GMT-03:00) Montevideo" },
  { value: "-02:00", label: "(GMT-02:00) Mid-Atlantic" },
  { value: "-01:00", label: "(GMT-01:00) Azores" },
  { value: "-01:00", label: "(GMT-01:00) Cape Verde Is." },
  { value: "+00:00", label: "(GMT+00:00) London" },
  { value: "+00:00", label: "(GMT+00:00) UTC" },
  { value: "+01:00", label: "(GMT+01:00) Berlin" },
  { value: "+01:00", label: "(GMT+01:00) Paris" },
  { value: "+01:00", label: "(GMT+01:00) West Central Africa" },
  { value: "+01:00", label: "(GMT+01:00) Amsterdam" },
  { value: "+01:00", label: "(GMT+01:00) Prague" },
  { value: "+01:00", label: "(GMT+01:00) Stockholm" },
  { value: "+01:00", label: "(GMT+01:00) Vienna" },
  { value: "+01:00", label: "(GMT+01:00) Warsaw" },
  { value: "+02:00", label: "(GMT+02:00) Cairo" },
  { value: "+03:00", label: "(GMT+03:00) Istanbul" },
  { value: "+03:00", label: "(GMT+03:00) Moscow" },
  { value: "+03:30", label: "(GMT+03:30) Tehran" },
  { value: "+04:00", label: "(GMT+04:00) Abu Dhabi" },
  { value: "+04:00", label: "(GMT+04:00) Baku" },
  { value: "+04:00", label: "(GMT+04:00) Muscat" },
  { value: "+04:00", label: "(GMT+04:00) Tbilisi" },
  { value: "+04:00", label: "(GMT+04:00) Yerevan" },
  { value: "+04:30", label: "(GMT+04:30) Kabul" },
  { value: "+05:00", label: "(GMT+05:00) Karachi" },
  { value: "+05:00", label: "(GMT+05:00) Tashkent" },
  { value: "+05:30", label: "(GMT+05:30) Kolkata" },
  { value: "+05:45", label: "(GMT+05:45) Kathmandu" },
  { value: "+06:00", label: "(GMT+06:00) Dhaka" },
  { value: "+06:00", label: "(GMT+06:00) Almaty" },
  { value: "+06:00", label: "(GMT+06:00) Ekaterinburg" },
  { value: "+06:30", label: "(GMT+06:30) Yangon (Rangoon)" },
  { value: "+07:00", label: "(GMT+07:00) Bangkok" },
  { value: "+07:00", label: "(GMT+07:00) Novosibirsk" },
  { value: "+08:00", label: "(GMT+08:00) Hong Kong" },
  { value: "+08:00", label: "(GMT+08:00) Krasnoyarsk" },
  { value: "+08:00", label: "(GMT+08:00) Kuala Lumpur" },
  { value: "+08:00", label: "(GMT+08:00) Singapore" },
  { value: "+08:00", label: "(GMT+08:00) Taipei" },
  { value: "+08:00", label: "(GMT+08:00) Perth" },
  { value: "+08:00", label: "(GMT+08:00) Ulaan Bataar" },
  { value: "+08:00", label: "(GMT+08:00) Urumqi" },
  { value: "+09:00", label: "(GMT+09:00) Irkutsk" },
  { value: "+09:00", label: "(GMT+09:00) Seoul" },
  { value: "+09:00", label: "(GMT+09:00) Tokyo" },
  { value: "+09:30", label: "(GMT+09:30) Adelaide" },
  { value: "+09:30", label: "(GMT+09:30) Darwin" },
  { value: "+10:00", label: "(GMT+10:00) Brisbane" },
  { value: "+10:00", label: "(GMT+10:00) Canberra" },
  { value: "+10:00", label: "(GMT+10:00) Guam" },
  { value: "+10:00", label: "(GMT+10:00) Hobart" },
  { value: "+10:00", label: "(GMT+10:00) Melbourne" },
  { value: "+10:00", label: "(GMT+10:00) Port Moresby" },
  { value: "+10:00", label: "(GMT+10:00) Sydney" },
  { value: "+10:00", label: "(GMT+10:00) Yakutsk" },
  { value: "+11:00", label: "(GMT+11:00) Vladivostok" },
  { value: "+12:00", label: "(GMT+12:00) Fiji" },
  { value: "+12:00", label: "(GMT+12:00) Marshall Islands" },
  { value: "+12:00", label: "(GMT+12:00) Magadan" },
  { value: "+12:00", label: "(GMT+12:00) Auckland" },
  { value: "+13:00", label: "(GMT+13:00) Nukualofa" },
];
