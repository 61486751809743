import { Form, Input, Radio, Skeleton, Switch } from "antd";
import { UseFetchUser } from "hooks/useUser";
import { FC } from "react";
import {
  UseFetchGallery,
  UseGalleryInfoMutation,
} from "views/Gallery/data/useGallery";

type FieldType = {
  _id: string;
  client: string; // ID
  createdBy: string; // ID
  dateCreated: Date;
  dateLastViewed: Date;
  dateModified: Date;
  isArchived: boolean;
  isDownloadable: boolean;
  isPasswordProtected: boolean;
  isPublic: boolean;
  name: string;
  password: string;
  emailRegistration: boolean;
  isSocialEnabled: boolean;
  slug: string;
  security: string;
  downloadResolution: "web" | "print";
};

type GalleryNewFormProps = {
  galleryId: string | undefined;
  onFinishPath?: string;
};

export const GalleryInfoForm: FC<GalleryNewFormProps> = ({
  galleryId,
  onFinishPath,
}) => {
  const { data, isLoading } = UseFetchGallery(galleryId);
  const { data: userData, isLoading: isUserLoading } = UseFetchUser();
  const { mutate } = UseGalleryInfoMutation();
  // const navigate = useNavigate();
  // const onFinish = (formData: FieldType) => {
  //   // @ts-ignore
  //   mutate({ id: galleryId, data: formData });
  //   navigate(onFinishPath || "/gallery");
  // };

  const isValidFormData = (data: FieldType) => {
    return (
      !data.isPasswordProtected || (data.isPasswordProtected && data.password)
    );
  };

  const handleChange = (e: any, f: any) => {
    const formData = f.reduce(
      // @ts-ignore
      (a, b) => ({ ...a, [b.name.pop()]: b.value }),
      {}
    );

    if (isValidFormData(formData))
      // @ts-ignore
      mutate({ id: galleryId, data: formData });
  };

  // LIKE seriously WTF?
  // UseQUERY has some strange cache behavior
  // and I dont have time to figure it out rightn now.
  const isGalleryIdMismatch = data?._id !== galleryId;
  const isNewDocument = !data?._id;
  if (isLoading || isUserLoading || (isGalleryIdMismatch && !isNewDocument)) {
    return <Skeleton />;
  }

  const publicUrl = `http://${
    userData.internalURL
  }.${window.location.host.replace("www.", "")}/gallery/`;

  return (
    <Form
      name="basic"
      style={{ width: "100%" }}
      initialValues={data}
      // onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      onFieldsChange={handleChange}
    >
      {/* <Image
        src={`${STATIC_STORAGE_URL}/${data?.images?.shift()?.url.s}`}
        width={200}
      /> */}
      <Form.Item<FieldType> label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item<FieldType> label="URL slug" name="slug">
        <Input addonBefore={publicUrl} />
      </Form.Item>
      <Form.Item<FieldType> label="Security" name="security">
        <Radio.Group>
          <Radio.Button value="hidden">Hidden</Radio.Button>
          <Radio.Button value="public">Public</Radio.Button>
          <Radio.Button value="password">Password</Radio.Button>
          <Radio.Button value="email">Email Registration</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {/* <Form.Item<FieldType>
        label="Require password"
        name="isPasswordProtected"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item> */}
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.isPasswordProtected !== currentValues.isPasswordProtected
        }
      >
        {({ getFieldValue }) => {
          if (getFieldValue("security") === "password") {
            return (
              <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            );
          }
          if (getFieldValue("security") === "hidden") {
            return (
              <p>
                The gallery is not accesible to anyone but you
                <br />
                <br />
              </p>
            );
          }
          if (getFieldValue("security") === "public") {
            return (
              <p>
                The gallery is accesible to anyone with the link
                <br />
                <br />
              </p>
            );
          }
          if (getFieldValue("security") === "email") {
            return (
              <p>
                Users wil be required to register with an email to view the
                gallery
                <br />
                <br />
              </p>
            );
          }
          return null;
        }}
      </Form.Item>
      <Form.Item<FieldType>
        label="Allow photo downloads"
        name="isDownloadable"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.isDownloadable !== currentValues.isDownloadable
        }
      >
        {({ getFieldValue }) => {
          if (getFieldValue("isDownloadable") === true) {
            return (
              <Form.Item<FieldType>
                label="Resolution"
                name="downloadResolution"
              >
                <Radio.Group>
                  <Radio.Button value="web">Web</Radio.Button>
                  <Radio.Button value="print">Print</Radio.Button>
                </Radio.Group>
              </Form.Item>
            );
          }

          return null;
        }}
      </Form.Item>
      <Form.Item<FieldType>
        label="Show social sharing buttons"
        name="isSocialEnabled"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      {/* <Form.Item>
        <Button type="primary" htmlType="submit">
          SAVE
        </Button>
      </Form.Item> */}
    </Form>
  );
};
