import { message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";

const { Dragger } = Upload;

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

type GalleryUploadProps = {
  galleryId?: string;
};

export const GalleryImageUpload: FC<GalleryUploadProps> = ({ galleryId }) => {
  const queryClient = useQueryClient();

  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: `${REACT_APP_API_URL}/gallery/${galleryId}/image`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token") || null}`,
      "x-access-token": JSON.parse(sessionStorage.getItem("token") || ""),
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        queryClient.invalidateQueries({
          queryKey: ["fetchGallery"],
        });
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
    progress: {
      showInfo: false,
    },
    showUploadList: false,
  };

  return (
    <div style={{ width: "100%" }}>
      <Dragger {...props} style={{ marginBottom: 25 }}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        {/* <p className="ant-upload-hint">Support for a single or bulk upload.</p> */}
      </Dragger>
    </div>
  );
};
