import { UseListAllGalleries } from "../data/useGallery";
import { Skeleton, Drawer, MenuProps, Input, Dropdown, Space } from "antd";
import "./styles.css";
import { GalleryListB } from "./components/GalleryList2";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GalleryInfoForm } from "../manage/GalleryInfoForm";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { SyntheticEvent, useState } from "react";
import { ShareGallery } from "../share";
import { GalleryDocument } from "../data/service";

const { Search } = Input;

export const GalleryList = () => {
  const { galleryId } = useParams();
  const { data: galleryData, isLoading } = UseListAllGalleries();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchFilterOnChange = (event: SyntheticEvent) => {
    // @ts-ignore
    const val = event.target.value as string;
    setSearchTerm(val);
  };

  type MenuItem = Required<MenuProps>["items"][number];
  const items: MenuItem[] = [
    {
      label: <Link to="/gallery?sort=1">Ascending</Link>,
      key: "sortAscend",
      icon: <ArrowUpOutlined />,
    },
    {
      label: <Link to="/gallery?sort=-1">Descending</Link>,
      key: "sortDescend",
      icon: <ArrowDownOutlined />,
    },
  ];

  if (isLoading) {
    return <Skeleton />;
  }

  const dataFilter = (i: GalleryDocument) =>
    i.name?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());

  return (
    <>
      <div className="list-view-tool-bar">
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Space>
            Sort
            <DownOutlined />
          </Space>
        </Dropdown>
        <Search
          onChange={handleSearchFilterOnChange}
          allowClear
          size="small"
          enterButton={false}
          placeholder="Seach galleries"
        />
      </div>
      <GalleryListB
        data={searchTerm ? galleryData?.filter(dataFilter) : galleryData}
      />
      <Drawer
        // title="Manage Item"
        placement="right"
        open={location.pathname.includes("gallery/details")}
        onClose={() => navigate("/gallery")}
        size="large"
        destroyOnClose
      >
        <GalleryInfoForm galleryId={galleryId} />
      </Drawer>
      <ShareGallery />
    </>
  );
};
