import { FC, useState } from "react";
import { Button, Input } from "antd";
import { iSitePage, iMeta } from "../data/types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";

const { Search } = Input;

interface Props {
  handleCreateNewItem: (data: iSitePage) => void;
  handleUpdateItem: (data: iSitePage) => void;
  fetchData: () => Promise<void>;
  data: iSitePage;
  parentId?: string | undefined;
  images: iMeta[];
}

export const NewItemForm: FC<Props> = ({ handleUpdateItem, data }) => {
  const [isPageTitleInEditMode, setIsPageTitleInEditMode] = useState(false);
  const [isPageDescriptionInEditMode, setIsPageDescriptionInEditMode] =
    useState(false);
  const [pageDescriptionValue, setPageDescriptionValue] = useState("");

  // @ts-ignore
  const savePageName = (newPageName: string) => {
    if (newPageName) {
      handleUpdateItem({
        ...data,
        name: newPageName,
      });
    }
    setIsPageTitleInEditMode(false);
  };

  const savePage = () => {
    handleUpdateItem({
      ...data,
      description: pageDescriptionValue,
    });
    setIsPageDescriptionInEditMode(false);
  };

  return (
    <div className="web-designer-panel-layout-container">
      {isPageTitleInEditMode ? (
        <Search
          placeholder="input search text"
          allowClear
          enterButton="Save"
          size="large"
          defaultValue={data?.name}
          style={{ width: "500px" }}
          onSearch={savePageName}
        />
      ) : (
        <h1 style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {data?.name}
          <Button
            type="link"
            size="small"
            style={{ opacity: "0.3" }}
            onClick={() => setIsPageTitleInEditMode(true)}
          >
            Edit
          </Button>
        </h1>
      )}
      {isPageDescriptionInEditMode ? (
        <>
          <ReactQuill
            theme="snow"
            defaultValue={data?.description}
            onChange={setPageDescriptionValue}
          />
          <Button onClick={savePage}>Save</Button>
        </>
      ) : (
        <>
          <div
            dangerouslySetInnerHTML={{ __html: data?.description || "" }}
          ></div>
          <Button onClick={() => setIsPageDescriptionInEditMode(true)}>
            Edit
          </Button>
        </>
      )}
    </div>
  );
};
