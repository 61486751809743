import { Skeleton, Drawer } from "antd";
import { UseCreateNewGallery } from "../data/useGallery";
import { useLocation, useNavigate } from "react-router-dom";
import { GalleryInfoForm } from "views/Gallery/manage/GalleryInfoForm";
import { GalleryList } from "../list";
export const CreateNewGallery = () => {
  const { data: galleryId, isLoading } = UseCreateNewGallery();
  const navigate = useNavigate();
  const location = useLocation();

  if (isLoading) {
    return <Skeleton />;
  }
  // navigate(`/gallery/${data}`);
  return (
    <>
      <GalleryList />
      <Drawer
        // title="Manage Item"
        placement="right"
        open={location.pathname.includes("gallery/new")}
        onClose={() => navigate(`/gallery`)}
        size="large"
        destroyOnClose
      >
        <GalleryInfoForm
          galleryId={galleryId}
          onFinishPath={`/gallery/${galleryId}`}
        />
      </Drawer>
    </>
  );
  // return null;
};
