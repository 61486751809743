// @ts-nocheck
import { FC, useState } from "react";
import { Button, Popover, Dropdown } from "antd";
import {
  FileAddOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { iInventory } from "../data/types";
import { post } from "views/WebDesigner/data/inventoryService";
import { useParams } from "react-router-dom";
import "./styles.css";
import { NewItemForm } from "../DesignRender/NewItemForm";
import { useWebSiteMutation } from "../data/useInventory";
import { v4 as uuid4 } from "uuid";
import { useIsMobile } from "hooks/useIsMobile";

export const getSortedChildren = (data: iInventory[], id: string) => {
  return data
    ?.filter((i) => i.parentId === id)
    .sort((a, b) => {
      const intA = parseInt(a.name || "");
      const intB = parseInt(b.name || "");

      if (isNaN(intA) || isNaN(intB)) {
        const nA = a.name?.toUpperCase() || 0;
        const nB = b.name?.toUpperCase() || 0;
        if (nA < nB) {
          return -1;
        }
        if (nA > nB) {
          return 1;
        }
        return 0;
      }

      return intA - intB;
    });
};

type NavigationBuilderProps = {
  webSiteData?: InventoryDocument;
};

export const NavigationBuilder: FC<NavigationBuilderProps> = ({
  webSiteData,
}) => {
  const { data } = webSiteData;
  const [selectedParentId, setSelectedParentId] = useState<string>();
  const [selectedData, setSelectedData] = useState<iInventory | undefined>(
    data.find((i) => i.id === "parent")
  );
  const { mutate } = useWebSiteMutation();
  const { userId } = useParams();
  const isMobile = useIsMobile();

  const onAddItem = (mutatedItem: iInventory) => {
    const newInventoryState = data?.concat(mutatedItem);
    if (userId) post(userId, newInventoryState);
  };

  const onUpdateItem = (mutatedItem: iInventory) => {
    mutatedItem.parentId = selectedParentId;
    if (Object.hasOwn(mutatedItem, "id")) {
      const newInventoryState = data
        ?.filter((i) => i.id !== mutatedItem?.id)
        .concat([mutatedItem]);
      console.log(newInventoryState);
      mutate({ id: userId, data: newInventoryState });
    } else {
      mutatedItem.id = uuid4();
      mutate({ id: userId, data: data.concat(mutatedItem) });
    }
  };

  const onDeleteItem = (id: string) => {
    const mutatedData = data?.filter((i) => i.id !== id);
    if (userId) post(userId, mutatedData);
  };

  const showNewItemDrawer = (
    parentId: string | undefined,
    data?: iInventory
  ) => {
    setSelectedParentId(parentId);
    setSelectedData(data || { name: "NEW PAGE" });
  };

  const render: any = (id: string) => {
    const item = data?.find((i) => i.id === id);
    if (!item) return null;

    const children = getSortedChildren(data, id);

    const items = [
      {
        label: (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              showNewItemDrawer(item?.parentId, item);
            }}
            size="small"
            icon={<EditOutlined />}
            style={{ width: "100%" }}
          >
            View / Edit
          </Button>
        ),
        key: 3,
      },
      {
        label: (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              showNewItemDrawer(id);
            }}
            size="small"
            icon={<FileAddOutlined />}
            style={{ width: "100%" }}
          >
            Add sub-page
          </Button>
        ),
        key: 1,
      },
      // ...(!!item.parentId
      //   ?
      {
        label: (
          <Popover
            trigger="click"
            title="This action can't be undone. Are you sure?"
            content={
              <Button onClick={() => onDeleteItem(id)} danger>
                Confirm
              </Button>
            }
          >
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={(e) => e.stopPropagation()}
              style={{ width: "100%" }}
            >
              Delete
            </Button>
          </Popover>
        ),
        key: 2,
      },
      // : {}),
    ];

    return (
      <>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            style={{ marginBottom: "15px", width: "90%", textAlign: "left" }}
            icon={<DownOutlined />}
          >
            {item.name}
          </Button>
        </Dropdown>
        {children && (
          <div style={{ paddingLeft: "35px" }}>
            {children?.map((item) => render(item.id))}
          </div>
        )}
      </>
    );
  };

  const parent = data?.find((i) => !i.hasOwnProperty("parentId"));

  if (isMobile) {
    return <div> {parent && render(parent.id)}</div>;
  }

  return (
    <div className="web-designer-panel">
      <div className="web-designer-panel-nav">
        {parent && render(parent.id)}
      </div>
      <div className="web-designer-panel-layout">
        <NewItemForm
          handleCreateNewItem={onAddItem}
          handleUpdateItem={onUpdateItem}
          parentId={selectedParentId}
          data={selectedData}
        />
      </div>
    </div>
  );
};
