import { Skeleton } from "antd";
import { SiteLayout } from "publicContent/layout";
import { FC } from "react";
import { useFetchWebSite } from "views/WebDesigner/data/useInventory";

const buildNavLinks = ({ links, parentId }: any) => {
  const link = links.find((i: any) => i.id === parentId);
  <a href={link.id.toLocaleLowerCase()}>{}</a>;
};
export const PublicSite: FC = () => {
  const {
    data: res,
    isLoading,
    error,
  } = useFetchWebSite("666306e0d3856bd9a82660b5");
  const pageData = res?.data.find(
    (i) => i.name?.toLocaleLowerCase() === "home"
  );
  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    // let's log this stit
  }

  return (
    <SiteLayout navItems={res!.data}>
      <div
        dangerouslySetInnerHTML={{ __html: pageData?.description || "" }}
      ></div>
      <h1>Test</h1>
      <p>Blah</p>
    </SiteLayout>
  );
};
