import { Drawer } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { CopyGalleryLink } from "./components/CopyLink";
import { FC } from "react";

type ShareGalleryProps = {
  onClosePath?: string;
};

export const ShareGallery: FC<ShareGalleryProps> = ({ onClosePath }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Drawer
      placement="right"
      open={location.pathname.includes("/share")}
      onClose={() => navigate(onClosePath || "/gallery")}
      size="large"
      destroyOnClose
    >
      <CopyGalleryLink />
    </Drawer>
  );
};
