import "./App.css";
import { AppLayout } from "./components/Layout";
import { UseUserAuth } from "hooks/useUserAuth";
import { AppRoutes, UnathenticatedRoutes } from "routes";
import { Content } from "antd/es/layout/layout";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// const queryClient = new QueryClient();

function App() {
  const auth = UseUserAuth();

  return (
    <div className="App">
      {auth.isAuthenticated && auth.userData ? (
        // <QueryClientProvider client={queryClient}>
        <AppLayout auth={auth}>
          <AppRoutes />
        </AppLayout>
      ) : (
        // </QueryClientProvider>
        <Content
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexFlow: "column",
          }}
        >
          <UnathenticatedRoutes auth={auth} />
        </Content>
      )}
    </div>
  );
}

export default App;
