import { FC } from "react";
import { Form, Input, Button, Checkbox, Spin, Alert } from "antd";
import "./index.css";
import { AuthType } from "hooks/useUserAuth";
import { Link } from "react-router-dom";

export interface LoginFormProps {
  auth: AuthType;
}

export const LoginForm: FC<LoginFormProps> = ({ auth }) => {
  return (
    <div className="login-form-container">
      <h2 className="cactus-classical-serif-regular">Gallery Storm</h2>
      <div style={{ fontSize: ".8em", color: "#aaa" }}>
        Don't have an account? <Link to="./registration">Sign Up</Link>
      </div>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={({ email, password }) => auth.login(email, password)}
        // onFinishFailed={onLoginFailed}
        autoComplete="off"
        className="login-form"
      >
        <Form.Item
          label="Username"
          name="email"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input className="login-input" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password className="login-input" />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            disabled={auth.isLoading}
          >
            {auth.isLoading ? <Spin /> : "Login"}
          </Button>
        </Form.Item>
        {auth.isAccountVerified === false && (
          <Alert message="Verify your email address" type="error" closable />
        )}
      </Form>
    </div>
  );
};
