import api from "services/base-api";

const baseRoute = "/gallery";

export type ImageURLDocument = {
  [url: string]: {
    s: string;
    l: string;
  };
};

export type ImageSizeType = {
  width: number;
  height: number;
};
export type ImageDocument = {
  id: string;
  url: ImageURLDocument;
  size: ImageSizeType;
};

export type GalleryDocument = {
  _id: string;
  slug: string;
  client?: string; // ID
  createdBy?: string; // ID
  dateCreated?: Date;
  dateLastViewed?: Date;
  dateModified?: Date;
  images?: ImageDocument[];
  isArchived?: boolean;
  isDownloadable?: boolean;
  isPublic?: boolean;
  name?: string;
  password?: string;
};

export const list = async (id?: string): Promise<GalleryDocument[]> =>
  await api.get(`${baseRoute}`);

export const create = async (): Promise<GalleryDocument[]> =>
  await api.post(`${baseRoute}`);

export const get = async (id?: string): Promise<GalleryDocument> =>
  await api.get(`${baseRoute}/${id}`);

export const put = async (id: string, data: GalleryDocument): Promise<string> =>
  await api.put(`${baseRoute}/${id}`, data);

export const destroy = async (galleryId: string): Promise<void> =>
  api.destroy(`${baseRoute}/${galleryId}`);

export const destroyImage = async (
  galleryId: string,
  imgId: string
): Promise<void> => api.destroy(`${baseRoute}/${galleryId}/image/${imgId}`);
