import { Route, Routes } from "react-router-dom";
import { PublicGallery } from "./gallery/index";
import { PageNotFound } from "routes";
import { PublicSite } from "./site";

export const PublicContentRoutes = () => (
  <Routes>
    <Route path="/gallery/:galleryId" element={<PublicGallery />} />
    <Route path="/" element={<PublicSite />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);
