import { Link } from "react-router-dom";
import "./style.css";
import { Subscription } from "views/Subscription";

export const Landing = () => {
  return (
    <>
      <div className="marketing-container">
        <div className="marketing-header">
          <h1 className="app-logo">GALLERY STORM</h1>
          <div className="marketing-nav">
            <a href="//app.gallerystorm.com">Pricing</a>
            <a href="//app.gallerystorm.com">Log in</a>
            <a href="//app.gallerystorm.com/registration">Sign up</a>
          </div>
        </div>

        {/* <h3 style={{ textAlign: "center" }}>Simple. Modrern. Useful</h3>
      <h4 style={{ textAlign: "center" }}>Deliver. Share. Proof. Sell.</h4> */}

        {/* <header>
          <div className="container">
            <h1>GALLERY STORM</h1>
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">Features</a>
              </li>
              <li>
                <a href="#">Pricing</a>
              </li>
              <li>
                <a href="#">Contact</a>
              </li>
            </ul>
          </div>
        </header> */}

        <section className="showcase">
          <div className="container">
            <h1>Capture. Share. Sell.</h1>
            <p className="cactus-classical-serif-regular">
              Welcome to GALLERY STORM - the ultimate web app for photographers.
            </p>
          </div>
        </section>

        {/* <section className="main-content"> */}
        <div className="container">
          <h2
            style={{ textAlign: "center" }}
            className="cactus-classical-serif-regular"
          >
            Why GALLERY STORM?
          </h2>
          <div className="landing-grid">
            <div className="landing-col">
              <h3>Effortless Delivery</h3>
              <p className="cactus-classical-serif-regular">
                Instantly deliver high-resolution images to your clients with
                secure, customizable galleries. Your clients can view, download,
                and enjoy your photos with just a few clicks.
              </p>
            </div>
            <div className="landing-col">
              <h3>Seamless Sharing</h3>
              <p className="cactus-classical-serif-regular">
                Share your stunning visuals with the world. GALLERY STORM makes
                it easy to share your work on social media, via email, or
                through direct links. Showcase your portfolio and attract new
                clients effortlessly.
              </p>
            </div>
            <div className="landing-col">
              <h3>Professional Proofing</h3>
              <p className="cactus-classical-serif-regular">
                Simplify the proofing process with our easy-to-use proofing
                tools. Clients can review, select, and comment on photos
                directly within their gallery, making collaboration smooth and
                efficient.
              </p>
            </div>
            <div className="landing-col">
              <h3>Simplified Selling</h3>
              <p className="cactus-classical-serif-regular">
                Turn your passion into profit. Sell prints, digital downloads,
                and more with our integrated e-commerce features. Set your
                prices, manage orders, and track sales, all within GALLERY
                STORM.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="marketing-copy"> */}
        {/* <section className="showcase-2"> */}

        <section className="marketing-copy">
          <section className="container">
            <h2>Key Features</h2>

            <ul className="cactus-classical-serif-regular">
              <li>
                Customizable Galleries: Tailor each gallery to reflect your
                brand and style.
              </li>
              <li>
                Secure Access: Protect your work with password-protected
                galleries and watermarks.
              </li>
              <li>
                Client Proofing: Get feedback and approvals quickly with
                intuitive proofing tools.
              </li>
              <li>
                Integrated E-commerce: Sell your photos directly from your
                galleries with seamless payment integration.
              </li>
              <li>
                Social Media Integration: Share your work instantly on all major
                social platforms.
              </li>
              <li>
                High-Resolution Downloads: Provide clients with the quality they
                expect.
              </li>
            </ul>
          </section>
        </section>
        {/* </section> */}
        <section className="marketing-copy">
          <section className="container">
            <h2>Join GALLERY STORM Today</h2>
            <p>
              Experience the future of photography management. Whether you're a
              professional photographer or just starting out, GALLERY STORM is
              designed to help you succeed. Sign up now and take your
              photography business to new heights.
            </p>
          </section>
          {/* </div> */}
          {/* </section> */}

          <section className="container">
            <h2>Get Started</h2>
            <p>
              Ready to revolutionize your photography workflow? Join GALLERY
              STORM today and see how easy it is to deliver, share, proof, and
              sell your work.
            </p>
          </section>
        </section>
      </div>

      {/* </div> */}
      {/* </div> */}
      <Subscription />

      <footer>
        <div className="container">
          <p>
            Have questions or need assistance? Our support team is here to help.
            Reach out to us at{" "}
            <a href="mailto:support@GALLERY STORM.com">
              support@GALLERY STORM.com
            </a>
            .
          </p>
          <p>
            &copy; 2024 GALLERY STORM – Capturing moments, delivering
            excellence.
          </p>
        </div>
      </footer>
      {/* </div > */}
    </>
  );
};
