import { GalleryDocument } from "views/Gallery/data/service";
import { getImgURLs } from "../utils";
import { Link } from "react-router-dom";
import { FC, ReactElement } from "react";
import "./GalleryList2.css";
import { Button, Space } from "antd";
import {
  CloudUploadOutlined,
  EditOutlined,
  PlusCircleOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { useIsMobile } from "hooks/useIsMobile";

type GalleryListImageProps = {
  imgUrl: string;
};

const GalleryListImage: FC<GalleryListImageProps> = ({ imgUrl }) => {
  return (
    <img
      style={{ maxWidth: 150, maxHeight: 100 }}
      src={imgUrl}
      key={imgUrl}
      alt={imgUrl}
    />
  );
};

type GalleryListAProps = {
  data?: GalleryDocument[];
};

type ActionsButtonProps = {
  icon: ReactElement;
  onClick?: (e: any) => void;
};
const ActionsButton: FC<ActionsButtonProps> = ({ children, icon, ...rest }) => {
  return (
    <Button
      className="gallery-item-actions"
      type="link"
      size="small"
      icon={icon}
      {...rest}
    >
      {children}
    </Button>
  );
};
export const GalleryListB: FC<GalleryListAProps> = ({ data }) => {
  // const { mutate } = UseDeleteGallery();
  // const handleDeleteGalleryClick = (galleryId: string) => {
  //   mutate({ galleryId });
  // };

  const isMobile = useIsMobile();

  return (
    <div className="gallery-list">
      <div
        className={isMobile ? "gallery-add-new-item" : "gallery-list-item"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to="/gallery/new">
          <Button icon={<PlusCircleOutlined />} size="large" type="primary">
            Create New Gallery
          </Button>
        </Link>
      </div>
      {data?.map((i) => {
        return (
          <div className="gallery-list-item">
            <div className="gallery-list-item-menu">
              <h2>{i.name}</h2>
              {/* {i.isPublic ? <>Public</> : <>Private</>} */}
              {isMobile ? (
                <Link to={`/gallery/${i._id}`}>
                  <ActionsButton icon={<CloudUploadOutlined />}>
                    Manage Collection
                  </ActionsButton>
                </Link>
              ) : (
                <Space>
                  <Link to={`/gallery/details/${i._id}`}>
                    <ActionsButton icon={<EditOutlined />}>Edit</ActionsButton>
                  </Link>
                  <Link to={`/gallery/share/${i._id}`}>
                    <ActionsButton icon={<ShareAltOutlined />}>
                      Share
                    </ActionsButton>
                  </Link>
                  <Link to={`/gallery/${i._id}`}>
                    <ActionsButton icon={<CloudUploadOutlined />}>
                      Manage collection
                    </ActionsButton>
                  </Link>
                  {/* <Popover
                    trigger="click"
                    title="This action can't be undone. Are you sure?"
                    content={
                      <Button
                        danger
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDeleteGalleryClick(i._id)}
                      >
                        Confirm
                      </Button>
                    }
                  >
                    <ActionsButton
                      icon={<DeleteOutlined />}
                      onClick={(e) => e.stopPropagation()}
                    >
                      Delete
                    </ActionsButton>
                  </Popover> */}
                </Space>
              )}
            </div>
            <div className="gallery-list-item-img-container">
              {getImgURLs(i.images).map((url) => (
                <GalleryListImage imgUrl={url} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
