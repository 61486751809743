import { useState, FC } from "react";
import { Button, Drawer, Layout } from "antd";
import { AuthType } from "hooks/useUserAuth";
import { PrimaryNavigation } from "./PrimaryNavigation";
import "./styles.css";
import { iSitePage } from "views/WebDesigner/data/types";
const { Header } = Layout;

interface Props {
  navItems: iSitePage[];
}

export const MobileNavLayout: FC<Props> = ({ navItems }) => {
  const [isNavPanelOpen, setIsNavPanelOpen] = useState(false);

  return (
    <>
      <Header className="mobile-header">
        <span className="cactus-classical-serif-regular">Gallery Storm</span>
        <Button onClick={() => setIsNavPanelOpen(true)}>=</Button>
      </Header>
      <Drawer
        open={isNavPanelOpen}
        onClose={() => setIsNavPanelOpen(false)}
        onClick={() => setIsNavPanelOpen(false)}
      >
        <PrimaryNavigation navItems={navItems} />
      </Drawer>
    </>
  );
};
