import api from "services/base-api";

const baseRoute = "/publicGallery";

export type ImageURLDocument = {
  [url: string]: {
    s: string;
    l: string;
  };
};

export type ImageSizeType = {
  width: number;
  height: number;
};

export type ImageDocument = {
  id: string;
  url: ImageURLDocument;
  size: ImageSizeType;
};

export type GalleryDocument = {
  _id: string;
  slug: string;
  client?: string; // ID
  createdBy?: string; // ID
  dateCreated?: Date;
  dateLastViewed?: Date;
  dateModified?: Date;
  images?: ImageDocument[];
  isArchived?: boolean;
  isDownloadable?: boolean;
  isPublic?: boolean;
  name?: string;
  password?: string;
  isSocialEnabled: boolean;
  downloadResolution: string;
  security: string;
};

export enum SecurityEnum {
  email,
  public,
  password,
}

export type GetReturnType = {
  companyName: string;
  security: string; // SecurityEnum;
  gallery: GalleryDocument;
};

// export const list = async (id?: string): Promise<GalleryDocument[]> =>
//   await api.get(`${baseRoute}`);

export const get = async (
  internalURL?: string,
  id?: string
): Promise<GetReturnType> => await api.get(`${baseRoute}/${internalURL}/${id}`);

// export const put = async (id: string, data: GalleryDocument): Promise<string> =>
//   await api.put(`${baseRoute}/${id}`, data);
