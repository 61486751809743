import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CiCircleFilled,
} from "@ant-design/icons";
import { Button, Space } from "antd";
import { useSendAccountConfirmation } from "hooks/useSendAccountConfirmation";
import { FC, useState } from "react";

// import
export const AccountNotVerifiedBanner: FC = () => {
  const { mutate } = useSendAccountConfirmation();
  const [actionState, setActionState] = useState("new");
  const handleClick = async () => {
    // setActionState("loading");
    await mutate();
    // setActionState("complete");
  };

  return (
    <>
      {actionState === "complete" ? (
        <Space>
          <CheckCircleOutlined />
          Sent. Check your email.
        </Space>
      ) : (
        <>
          To keep your account safe and secure, we’ve sent an email to verify
          your email address and activate your account.
          <Button
            onClick={handleClick}
            type="link"
            disabled={actionState === "loading"}
          >
            Resend confirmation email.
          </Button>
        </>
      )}
    </>
  );
};
