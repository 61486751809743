import { useQuery } from "@tanstack/react-query";
import { get } from "./service";

export const UseFetchGallery = (internalURL?: string, galleryId?: string) => {
  return useQuery({
    queryKey: ["fetchGallery", galleryId, internalURL],
    queryFn: () => get(internalURL, galleryId),
    enabled: !!galleryId,
  });
};
