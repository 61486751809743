import { Flex, QRCode } from "antd";
import { FC } from "react";

type GalleryQrCodeProps = {
  link: string;
};
export const GalleryQrCode: FC<GalleryQrCodeProps> = ({ link }) => {
  return (
    <Flex justify="center">
      <QRCode value={link} />
    </Flex>
  );
};
