import { NavigationBuilder } from "./NavBuilder";
import { useParams } from "react-router-dom";
import { SiteDocument } from "views/WebDesigner/data/inventoryService";
import { useFetchWebSite } from "./data/useInventory";

export const WebDesigner = () => {
  const { userId } = useParams();
  const { data, isLoading } = useFetchWebSite(userId);

  if (isLoading) {
    return null;
  }

  const tempData: SiteDocument = data || {
    // @ts-ignore
    id: userId,
    data: [{ id: "parent", name: "Home" }],
  };

  return <NavigationBuilder webSiteData={tempData} />;
};
