import "./index.css";

export const Subscription = () => {
  return (
    <>
      <h1 className="subscription-header">Choose your tier</h1>
      <div className="tiers">
        <div className="tier-col">
          <h2>FREE</h2>
          <div className="cta-wrapper">
            <button>Choose</button>
          </div>
        </div>
        <div className="tier-col">
          <h2>Starter</h2>
          <div className="cta-wrapper">
            <button>Choose</button>
          </div>
        </div>
        <div className="tier-col">
          <h2>PRO</h2>
          <div className="cta-wrapper">
            <button>Choose</button>
          </div>
        </div>
      </div>
    </>
  );
};
