import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SiteDocument, get, post } from "./inventoryService";

export const useFetchWebSite = (userId?: string) => {
  return useQuery({
    queryKey: ["fetchInventory"],
    queryFn: () => get(userId || ""),
    enabled: !!userId,
  });
};

type mutationProps = {
  id: string;
  data: SiteDocument;
};

export const useWebSiteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (vars: mutationProps) => await post(vars.id, vars.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["fetchInventory"],
        // exact: true,
        refetchType: "all",
      });
    },
  });
};
