import { FC } from "react";
import { UseFetchUser } from "hooks/useUser";
import { useParams } from "react-router-dom";
import { UseFetchGallery } from "views/Gallery/data/useGallery";
import { Skeleton } from "antd";
import { GalleryQrCode } from "./QrCode";
import "./layout.css";

export const CopyGalleryLink: FC = () => {
  const { data: userData, isLoading: isUserLoading } = UseFetchUser();
  const { galleryId } = useParams();
  const { data: galleryData, isLoading: isGaleryLoading } =
    UseFetchGallery(galleryId);

  if (isUserLoading || isGaleryLoading) {
    return <Skeleton />;
  }

  const publicUrl = `${userData.internalURL}.${
    window.location.host.split(".")[1]
  }.com/gallery/${galleryData?.slug}`;

  return (
    <div className="wrapper">
      <div style={{ marginBottom: "100px" }}>
        <GalleryQrCode link={publicUrl} />
      </div>
      <div>{publicUrl}</div>
      <p>
        <a href={`http://${publicUrl}`} target="_blank">
          View Gallery
        </a>
      </p>
      {/* <EmailGalleryLink /> */}
    </div>
  );
};
