import { FC, useEffect, useState } from "react";
import { ImageDocument } from "views/Gallery/data/service";
import "./styles.css";
import { Skeleton } from "antd";
import { UseFetchGallery } from "../data/useGallery";
import { useIsMobile } from "hooks/useIsMobile";
import { buildStaticURL } from "utils";
import { Link } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";

//@ts-ignore
function buildMultidimensionalArray(arr) {
  const arrays = [[], [], [], []];
  for (let i = 0; i < arr.length; i++) {
    // @ts-ignore
    arrays[i % 4].push(arr[i]);
  }

  return arrays;
}

type GalleryImageProps = {
  imgData: ImageDocument;
  galleryId?: string;
};
const GalleryImage: FC<GalleryImageProps> = ({ imgData, galleryId }) => {
  return (
    <div className="grid-item">
      <Link to={`/gallery/${galleryId}/image/${imgData.id}`}>
        <img
          src={buildStaticURL(imgData.url.s)}
          style={{ width: "100%" }}
          alt={galleryId}
        />
      </Link>
    </div>
  );
};

type GalleryImagesType = {
  galleryId?: string;
};

export const GalleryItems: FC<GalleryImagesType> = ({ galleryId }) => {
  const isMobile = useIsMobile();
  const [imageState, setImageState] = useState([]);
  const { data, isLoading } = UseFetchGallery(galleryId);
  const images = data?.images || [];

  useEffect(() => {
    if (data?.images) {
      // @ts-ignore
      setImageState(data?.images);
    }
  }, [data]);

  if (isLoading) {
    return <Skeleton />;
  }

  // return (
  //   <ReactSortable list={imageState} setList={setImageState} className="grid">
  //     {imageState.map((item: ImageDocument, index: number) => (
  //       <GalleryImage imgData={item} galleryId={galleryId} />
  //     ))}
  //   </ReactSortable>
  // );

  return (
    <>
      <div className="grid">
        {/* <ReactSortable list={imageState} setList={setImageState} className="grid"> */}
        {isMobile
          ? imageState.map((item: ImageDocument, index: number) => (
              <GalleryImage imgData={item} galleryId={galleryId} />
            ))
          : buildMultidimensionalArray(images).map((column) => (
              <div className="grid-column">
                {column.map((item: ImageDocument, index: number) => (
                  <GalleryImage
                    key={item.id}
                    imgData={item}
                    galleryId={galleryId}
                  />
                ))}
              </div>
            ))}
        {/* </ReactSortable> */}
      </div>
    </>
  );
};
