import { useMutation } from "@tanstack/react-query";
import api from "../services/base-api";
import { UserType, UserWithTokenType } from "types/User";
const baseRoute = "/users";

export const post = async () => await api.post("/accountconfirmation");

export const useSendAccountConfirmation = () => {
  return useMutation({ mutationFn: () => post() });
};
